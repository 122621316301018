.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(255, 251, 239, 1);
}

.bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-image: url('../assets/tickets_backdrop.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 65%;
  opacity: 80%;
}

.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: fixed;
  top: var(--n-b-top);
  bottom: var(--n-b-bottom);
  left: var(--n-b-left);
  right: var(--n-b-right);
  margin: 0 auto;
  font-size: 20px;
  /* background-color: blue; */
}

.title {
  font-family: Isenheim, 'Times New Roman';
  font-weight: lighter;
  text-transform: uppercase;
  font-size: 70px;
  margin-bottom: 0px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

.pricing {
  list-style-type: none;
  width: 70%;
  padding: 0px;
}

.pricing li {
  display: flex;
  justify-content: space-between;
  font-family: Isenheim, 'Times New Roman';
  font-size: 1.5rem;
  margin-bottom: 8vh;
  position: relative;
}

.pricing li:last-child {
  margin-bottom: 0px;
}

.tier,
.price {
  white-space: nowrap;
  margin: 0px;
}

.dot {
  border-bottom: dotted 3px black;
  width: 100%;
  margin: 6px 2vw;
}

.bonus {
  font-size: 20px !important;
  position: absolute;
  left: 2vw;
  top: 2vh;
}

.purchase {
  font-size: 36px;
  margin: 0 !important;
}

.purchaseLink {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 2px solid black;
  display: flex;
  align-items: flex-end;
}
.purchaseLink p {
  padding-right: 1vw;
  margin: 0px;
}
.purchaseLink img {
  height: 30px;
  padding-bottom: 2px;
}

@media only screen and (max-width: 767px) {
  .bg {
    background-repeat: no-repeat;
    background-position-y: 95%;
  }

  .view {
    top: var(--n-b-top_mobile);
    bottom: var(--n-b-bottom_mobile);
    left: 0;
    right: 0;
    width: 85%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .bonus {
    font-size: var(--t-body) !important;
  }

  .pricing {
    width: 90%;
    margin-bottom: 2vh;
  }
  .pricing li {
    /* width: 90%; */
    margin-bottom: 4.7vh;
  }

  .title {
    font-family: Isenheim, 'Times New Roman';
    text-transform: uppercase;
    font-size: 50px;
    margin-bottom: 0px;
    margin-top: 50px;
  }

  .purchase {
    font-size: 24px;
    margin-bottom: 50px !important;
  }

  .purchaseLink img {
    height: 21px;
    margin-top: 2vh;
  }
}
