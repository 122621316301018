.mobile {
  display: none;
  position: fixed;
  overflow: auto;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/tickets_backdrop.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 65%;
  opacity: 80%;
}

.desktop {
  display: block;
}

@media only screen and (max-width: 767px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}
