.container,
.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.container {
  background: transparent;
}

.backdrop {
  background: rgba(255, 251, 239, 1);
  background-image: url('../assets/landing_backdrop.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: var(--n-b-top);
  bottom: var(--n-b-bottom);
  left: var(--n-b-left);
  right: var(--n-b-right);
  width: 60vw;
  margin: 0 auto;
  font-size: 20px;
}

.bunbun {
  position: fixed;
  z-index: 1;
  width: 120vw;
  bottom: 0;
}

.view p {
  width: 80%;
  font-size: var(--t-body);
  margin: 0.5vh;
  line-height: 1.2;
}

.logo {
  align-self: center;
  height: 27vh;
  padding-left: calc((var(--n-b-left)) / 2);
  margin: 2vh auto;
}

.logo img {
  height: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.view .welcome {
  /* font-size: 32px; */
  font-size: auto;
}

.lastLook {
  text-transform: uppercase;
  font-family: Isenheim, 'Times New Roman';
  margin-top: 3vh !important;
}

@media only screen and (max-width: 767px) {
  .view {
    top: var(--n-b-top_mobile);
    bottom: var(--n-b-bottom_mobile);
    left: 0;
    right: 0;
    width: 90%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .view p {
    font-size: var(--t-body-mobile);
    white-space: pre-line;
  }
  .view .welcome {
    display: none;
  }
  .logo {
    padding: 0px;
    margin: 2vh 2vh;
    height: auto;
  }
  .logo img {
    height: auto;
    width: 100%;
  }
  .bunbun {
    width: 100vw;
    height: 50vh;
    object-fit: cover;
    right: 0;
  }
}
