:root {
  --n-b-top: 7vh;
  --n-b-bottom: 5vh;
  --n-b-left: 100px;
  --n-b-right: 225px;
  --n-b-top_mobile: 50px;
  --n-b-bottom_mobile: 130px;

  --t-title: 90px;
  --t-subheader: 36px;
  --t-body-mobile: 16px;
  --t-body: 18px;
  --t-meta: 36px;
  --t-nav: 36px;
  --t-lines-nav: 36px;
}

body {
  cursor: none !important;
  background-color: #f4f3ef;
  line-height: 1.2;
}

#viewport {
  width: 100vw;
  height: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
}

iframe {
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.isenheim {
  font-family: Isenheim;
  font-weight: 600;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
