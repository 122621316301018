.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: -10vh;
  bottom: 0;
  width: 100vw;
  overflow: auto;
  margin: 0 auto;
  font-family: Isenheim, 'Times New Roman' !important;
  font-size: 24px;
}

.container * {
  text-decoration: none;
  color: black;
}

.link {
  display: flex;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  padding: 1.5vh 6vw;
  margin-bottom: 1vh;
  background: rgba(158, 169, 207, 0.2);
}

.link img {
  border: 1px solid black;
  border-radius: 50%;
  object-fit: cover;
  width: 75px;
  height: 75px;
}

.link p {
  padding: 0 5vw;
}
