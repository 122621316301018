.photo-group-container {
  margin-bottom: 50px;
}
.photo-group h2 {
  margin-bottom: 20px;
}
.photo-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.person-wrapper {
  width: calc(33.33% - 10px);
  margin-bottom: 20px;
  position: relative;
  /* height:500px; */
  /* background-color:red;  */
}

.person-wrapper:nth-child(3n + 1) {
  margin-right: 0;
}
.person-wrapper img {
  width: 100%;
  height: 100% !important;
  display: block;
  object-fit: cover;
  object-position: center bottom;
  /* background-color:red; */
}
.person-name {
  font-weight: 600;
  padding-bottom: 0.7vh;
}

.photo-wrapper {
  width: 100%;
  height: 22.5vh;
}

.emily img {
  height: 85% !important;
  object-fit: contain !important;
}
.emily {
  display: flex;
  align-content: flex-end;
}

@media only screen and (min-width: 500px) {
  .photo-wrapper {
    height: 27vh;
  }
}

@media only screen and (min-width: 600px) {
  .photo-wrapper {
    height: 30vh;
  }
  .photo-wrapper img {
    object-fit: contain !important;
  }
}

.photo-description {
  background-color: transparent;
}

.photo-description {
  background-color: transparent !important;
  font-family: Isenheim, 'Times New Roman';
  box-shadow: none !important ;
  padding: 10px;
  font-size: 2px;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photo-description p {
  color: black !important;
  background-color: transparent;
  font-size: 12px;
  margin: 0;
  font-family: Isenheim, 'Isenheim Regular';
  text-align: center;
}
